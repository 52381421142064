export type Apps = 'gealium' | 'agrosocial'
export type AppEnv = 'local' | 'test' | 'prod' | 'dev'

import type { Config } from '../config/gealium/config'

interface AppConfiguration {
  [key: string]: {
    theme: typeof Config
    tailwind: Record<string, any>
  }
}

const AppConfigurations: AppConfiguration = {
  gealium: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    theme: require('../config/gealium/config').Config,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    tailwind: require('../config/gealium/tailwindColors').tailWindColors,
  },
  agrosocial: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    theme: require('../config/agrosocial/config').Config,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    tailwind: require('../config/agrosocial/tailwindColors').tailWindColors,
  },
}

class ThemeProvider {
  getAppName(): Apps {
    return (
      (process.env.NEXT_PUBLIC_APP_NAME?.toLowerCase() as Apps) || 'gealium'
    )
  }

  getMarketPlaceConfig(): typeof Config {
    const appName = this.getAppName()
    return AppConfigurations[appName]?.theme || ({} as typeof Config)
  }

  getMarketPlaceTailwindColors(): Record<string, any> {
    const appName = this.getAppName()
    return AppConfigurations[appName]?.tailwind || {}
  }

  getFirebaseConfig() {
    const theme = this.getMarketPlaceConfig()

    const env = process.env.EXPO_PUBLIC_APP_ENV || 'dev'
    return (theme.firebase as any)[env] as {
      iosClientId: string
      expoClientId: string
    }
  }
}

export const themeProvider = new ThemeProvider()
export const sodiumConfig = themeProvider.getMarketPlaceConfig()
