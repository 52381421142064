export const tailWindColors = {
  lime: {
    '50': 'hsl(72, 100%, 95%)',
    '100': 'hsl(75, 100%, 89%)',
    '200': 'hsl(76, 100%, 79%)',
    '300': 'hsl(78, 99%, 67%)',
    '400': 'hsl(79, 91%, 55%)',
    '500': 'hsl(80, 95%, 44%)',
    '600': 'hsl(81, 100%, 37%)',
    '700': 'hsl(82, 91%, 27%)',
    '800': 'hsl(82, 81%, 23%)',
    '900': 'hsl(84, 71%, 20%)',
    '950': 'hsl(86, 96%, 10%)',
  },
  primary: {
    DEFAULT: 'hsl(81, 100%, 37%)',
    foreground: 'hsl(var(--primary-foreground))',
  },
}
