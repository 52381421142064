import { Bean } from 'lucide-react'
import { useLottie } from 'lottie-react'
import { FaSeedling } from 'react-icons/fa6'

const AgroSocialLogo = () => {
  const { View } = useLottie({
    animationData: require('./loading.json'),
    loop: false,
    style: { width: 120, height: 110 },
  })

  return <>{View}</>
}

export const Config = {
  loading: '',
  isMarketPlace: false,
  colors: {
    primary: '#005d62',
    iconColor: '#005d62',
    selectedIconColor: '#003944',
  },
  keywords: {
    likes: 'Seeds',
  },
  icons: {
    likeDefault: <Bean className="w-6 h-6" color="#005d62" />,
    likePressed: <FaSeedling size={20} color="#005d62" />,
  },
  login: {
    toRadialColor: '#003944',
    fromRadialColor: '#008075',
    loginImage: <AgroSocialLogo />,
  },
  buttons: {
    borderRadius: 50,
    textColor: '#bcf565',
    fontWeight: '600',
  },
}
