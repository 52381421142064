import { ThumbsUp } from 'lucide-react'
import Image from 'next/image'

export const Config = {
  loading: '',
  isMarketPlace: true,
  colors: {
    primary: '#4db7a3',
    iconColor: '#005d62',
    selectedIconColor: '#003944',
  },
  keywords: {
    likes: 'Likes',
  },
  icons: {
    likeDefault: <ThumbsUp className="w-6 h-6 text-gray-500" />,
    likePressed: <ThumbsUp className="w-6 h-6 text-blue-500" />,
  },
  login: {
    toRadialColor: '#4db7a3',
    fromRadialColor: '#4db7a3',
    loginImage: (
      <Image
        alt="logo"
        className="animate-fade-down"
        style={{ width: 200, height: 102 }}
        src={{
          src: 'https://assets-dev.gealium.com/gealium/Logo_Stacked_White_PNG_A3s1QJb5unh99h8c0kpYR.png',
          height: 102,
          width: 200,
        }}
      />
    ),
  },
}
